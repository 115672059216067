<div fxLayout="column" class="password-expire-container">

  <button mat-icon-button mat-dialog-close class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>

  <h2 mat-dialog-title>
    Your Password is expiring soon!
  </h2>

  <div class="mat-dialog-title-content">
    <div class="scroll-container" [innerHTML]="getDisplayMessage()">
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>
      Not yet
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true">
      Yes, change password
    </button>
  </div>
</div>