import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss']
})
export class UserAgreementComponent implements OnInit {

  public securityAgreementMessage: string;
  public userId: string;
  public clientCode: string;
  /* This flag signifies that the dialog is just to present the user agreement. No Accept/Decline buttons should be displayed. */
  isDisplayOnly: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {message: string, userId: string, clientCode: string, isDisplayOnly: boolean}) {}

  ngOnInit() {
    this.securityAgreementMessage = this.data.message;
    this.userId = this.data.userId;
    this.clientCode = this.data.clientCode;
    this.isDisplayOnly = this.data.isDisplayOnly || false;  // avoid sending undefined, for clarity
  }
}
