<div fxLayout="column" class="user-agreement-container">

  <button mat-icon-button
          mat-dialog-close
          class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>

  <h2 mat-dialog-title>
    User Agreement
  </h2>

  <div class="mat-dialog-title-content">
      <div class="scroll-container" [innerHTML]="securityAgreementMessage">
      </div>
  </div>

  <div mat-dialog-actions>
    <button ecaAutoFocus mat-button mat-dialog-close>
      {{ isDisplayOnly ? 'Close' : 'Decline' }}
    </button>
    <button mat-button
            color="primary"
            *ngIf="!isDisplayOnly"
            [mat-dialog-close]="true">
      Agree
    </button>
  </div>
</div>

