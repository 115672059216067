import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-password-expire',
  templateUrl: './password-expire.component.html',
  styleUrls: ['./password-expire.component.scss']
})
export class PasswordExpireComponent implements OnInit {
  daysLeft: number = 0;

  constructor(
    public dialogRef: MatDialogRef<PasswordExpireComponent>,

    @Inject(MAT_DIALOG_DATA) public data: { days: number }) { }

  ngOnInit() {
    this.daysLeft = this.data.days;
  }

  formatDaysLeft() {
    return this.daysLeft < 1 ? 'today' : (this.daysLeft === 1 ? 'tomorrow' : `in ${this.daysLeft} days`);
  }

  getDisplayMessage() {
    return `Your password will expire ${this.formatDaysLeft()}, would you like to reset it now?`;
  }
}
