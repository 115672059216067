import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeFilterPipe } from './attribute-filter';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { UserAgreementComponent } from './components/user-agreement/user-agreement.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PasswordExpireComponent } from './components/password-expire/password-expire.component';
import { EcaAutoFocusDirective } from './auto-focus.directive';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    AttributeFilterPipe,
    SanitizeHtmlPipe,
    UserAgreementComponent,
    PasswordExpireComponent,
    EcaAutoFocusDirective
  ],
  exports: [
    AttributeFilterPipe,
    SanitizeHtmlPipe,
    UserAgreementComponent,
    PasswordExpireComponent
  ]
})
export class SharedModule { }
